/**
 * OptimizePress3 Fb scroll event trigger:
 */
;(function() {
    window.addEventListener("scroll", maybeFbTrackScrollEvent);
    window.addEventListener("load", fbTrackPageViewEvent);

    function maybeFbTrackScrollEvent() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            // Trigger FB Scroll Event if enabled
            if (window.OPDashboardPixels && window.OPDashboardPixels.enableScrollEvent === "1") {
                let eventId = "OP3_Scroll" + Date.now();
                OP3Pixels.fireFacebookEvent(eventId, 'Scroll', []);
                window.removeEventListener('scroll', maybeFbTrackScrollEvent);
            }
        }
    }

    function fbTrackPageViewEvent() {
        // Trigger FB PageView Event
        if (window.OPDashboardPixels) {
            let eventId = "OP3_PageView" + Date.now();
            OP3Pixels.fireFacebookEvent(eventId, 'PageView', []);
        }
    }
})();
